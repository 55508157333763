/*
 * @Author: 马秋顺 1813637761@qq.com
 * @Date: 2022-12-20 14:49:03
 * @LastEditors: 马秋顺 1813637761@qq.com
 * @LastEditTime: 2023-01-06 10:45:26
 * @FilePath: \B2B\src\views\reportOfAnnual\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import service from "@/utils/request";

//专卖店年报接口
export function getYearReport(params) {
  return service({
    method: "get",
    url: "/exclusiveAnnual/findExclusiveAnnualReport.nd",
    params
  });
}