<!--
 * @Author: 马秋顺 1813637761@qq.com
 * @Date: 2022-12-19 08:58:57
 * @LastEditors: 马秋顺 1813637761@qq.com
 * @LastEditTime: 2024-01-12 10:28:48
 * @FilePath: \B2B\src\views\reportOfAnnual\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="report-of-annual">
        <audio id="musicfx" loop="loop" preload="auto">
            <source src="./images/bgMusic.mp3" type="audio/mpeg" id="hhh" />
        </audio>
        <div class="progress-report" v-if="type == 1" :style="{'background': 'url('+require('./images/loading-bg.png')+') no-repeat','background-size': '100% 100%'}">
            <img src="./images/loading.png" alt />
            <div class="progress-loading">
                <div class="progress-text">{{percentage}}<span>%</span></div>
            </div>
            <div class="progress-bg">
                <div class="progress-color"></div>
            </div>
            <div class="title">您的2023年年报生成中…</div>
        </div>
        <div id="nb-box" class="swiper-container" ref="mySwiper" v-if="type == 2">
            <div class="swiper-wrapper">
                <!-- 开始页面 -->
                <div class="swiper-slide" :style="{'background': 'url('+require('./images/home-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[50, 50]" psn="absolute" :ps="[264, 0, 0, 38]" class="ani ani-delay"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="0.5s">
                        <img src="./images/home-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[220, 58]" psn="absolute" :ps="[0, 0, 73, 78]" class="ani"
                        swiper-animate-effect="fadeIn" swiper-animate-duration="2s" swiper-animate-delay="1.2s"
                        @click="slideNext">
                        <img src="./images/home-2.png" alt style="object-fit: contain;" />
                    </auto-view>
                    <auto-view :wh="[200, 18]" psn="absolute" :ps="[0, 0, 40, 155]" class="ani"
                        swiper-animate-effect="fadeIn" swiper-animate-duration="1s" swiper-animate-delay="1.2s">
                        <p style="font-size: 13px; color: #777777;">信天翁出品</p>
                    </auto-view>
                </div>
                <!-- 加入海信 -->
                <div v-if="reportData.putDays > 0" class="swiper-slide" :style="{'background': 'url('+require('./images/yonghu-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[324, 38]" psn="absolute" :ps="[34, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="1s"
                        swiper-animate-delay="0.3s">
                        <div style="font-size:15px;">
                            <p style="line-height: 39px;" v-if="reportData.putYears.split('年')[0] >= 2023">
                                Hi，亲爱的家人，欢迎您加入海信！
                            </p>
                            <p style="line-height: 39px;" v-else>Hi，亲爱的家人。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[335, 160]" psn="absolute" :ps="[91, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p v-if="reportData.putYears.split('年')[0] < 2023">时至今日，您已经营</p>
                            <p v-else><i
                                    style="font-size: 17px; font-weight: 600;">{{reportData.putYears}}</i>至今，您已经营
                            </p>
                            <p style="line-height: 20px;">海信专卖店 <i
                                    style="font-size: 26px; color: #FF515F;font-weight: 600;">{{reportData.putDays}}</i><i
                                    style="font-size: 17px; font-weight: 600;">个</i> 日夜。</p>
                            <p>海信的发展，倾注了您和家人的支持、奉献</p>
                            <p>期待与您继续一路同行</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[324, 80]" psn="absolute" :ps="[278, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content" style="font-weight: 600; font-size: 17px;">
                            <p>共建百年海信，</p>
                            <p>让海信成为全球最值得信赖的品牌。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[107, 87]" psn="absolute" :ps="[143, 0, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yonghu-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[111, 183]" psn="absolute" :ps="[231, 13, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInDown" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yonghu-2.png" alt />
                    </auto-view>
                    <auto-view :wh="[375, 308]" psn="absolute" :ps="[350, 0, 0, 0]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/yonghu-3.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 辛勤工作 -->
                <div class="swiper-slide" v-if="reportData.loginTimes >= 5" :style="{'background': 'url('+require('./images/work-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[324, 66]" psn="absolute" :ps="[34, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p>信天翁始终助力您的发展之路，</p>
                            <p>2023年，您累计登录 <i
                                    style="font-size: 26px; color: #FF515F;font-weight: 600;">{{reportData.loginTimes}}</i><i
                                    style="font-size: 17px; font-weight: 600;">次</i>。</p>
                        </div>
                    </auto-view>
                    <auto-view v-if="reportData.loginLatestHours >= 18 || reportData.loginLatestHours < 6" :wh="[360, 117]"
                        psn="absolute" :ps="[131, 0, 0, 30]" class="ani" swiper-animate-effect="fadeInLeft"
                        swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p>还记得那些辛勤工作的日子吗？</p>
                            <p
                                style="font-size: 17px; font-weight: 600;display: flex;justify-content: space-between;align-items: center;">
                                {{reportData.loginLatestTimeStr}}</p>
                            <p>您仍在为门店经营而奋斗。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[58, 64]" psn="absolute" :ps="[92, 36, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/work-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[171, 136]" psn="absolute" :ps="[172, 0, 0, 0]" class="ani ani-delay" :zIndex="-1"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/work-2.png" alt />
                    </auto-view>
                    <auto-view :wh="[375, 458]" psn="absolute" :ps="[267, 0, 0, 0]" class="ani"
                        swiper-animate-effect="fadeInDown" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/work-3.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 学习情况 -->
                <div class="swiper-slide" :style="{'background': 'url('+require('./images/learn-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <template v-if="reportData.studyTime > 0">
                        <auto-view :wh="[324, 80]" psn="absolute" :ps="[34, 0, 0, 30]" class="ani"
                            swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s"
                            swiper-animate-delay="0.8s">
                            <div class="text-content">
                                <p style="font-size: 17px; font-weight: 600;">百舸争流，奋楫者先。</p>
                                <p>学习的每一分钟都值得被记录。</p>
                            </div>
                        </auto-view>
                        <auto-view :wh="[350, 80]" psn="absolute" :ps="[130, 0, 0, 30]" class="ani"
                            swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s"
                            swiper-animate-delay="0.8s">
                            <div class="text-content">
                                <p>这一年里，您通过信天翁海信学堂</p>
                                <p><i style="font-size: 17px; font-weight: 600;">累计</i>学习 <i
                                        style="font-size: 26px; font-weight: 600;color: #FF3B59;">{{reportData.studyTime}}</i><i
                                        style="font-size: 17px; font-weight: 600;">分钟</i>，获得 <i
                                        style="font-size: 26px; font-weight: 600;color: #FF3B59;">{{Number(reportData.credit).toFixed(1)}}</i><i
                                        style="font-size: 17px; font-weight: 600;">学分</i>。</p>
                            </div>
                        </auto-view>
                        <auto-view :wh="[350, 80]" psn="absolute" :ps="[230, 0, 0, 30]" class="ani"
                            swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s"
                            swiper-animate-delay="0.8s">
                            <div class="text-content" style="font-size: 17px; font-weight: 600;">
                                <p>博览新知，收获能力。</p>
                                <p>成就更专业的自己、收获更优质的门店！ </p>
                            </div>
                        </auto-view>
                    </template>
                    <template v-else>
                        <auto-view :wh="[324, 80]" psn="absolute" :ps="[34, 0, 0, 30]" class="ani"
                            swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s"
                            swiper-animate-delay="0.8s">
                            <div class="text-content">
                                <p>这一年里您从未使用过海信学堂，</p>
                                <p>赶紧打开信天翁登录<i style="font-size: 17px; color: #FF3B59;font-weight: 600;">海信学堂</i>吧！
                                </p>
                            </div>
                        </auto-view>
                        <auto-view :wh="[350, 78]" psn="absolute" :ps="[131, 0, 0, 30]" class="ani"
                            swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s"
                            swiper-animate-delay="0.8s">
                            <div class="text-content" style="font-weight: 600;">
                                <p>博览新知，收获能力，</p>
                                <p>成就更专业的自己、收获更优质的门店！ </p>
                            </div>
                        </auto-view>
                    </template>
                    <auto-view :wh="[175, 234]" psn="absolute" :ps="[0, 0, 42, 12]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/learn-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[219, 213]" psn="absolute" :ps="[385, 0, 0, 156]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/learn-2.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 顾客 -->
                <div v-if="reportData.purposeUserQty && reportData.dealUserQty" class="swiper-slide" :style="{'background': 'url('+require('./images/guke-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[254, 119]" psn="absolute" :ps="[34, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">这一年您共得到 <i style="font-size: 26px; color: #FF515F; font-weight: 600;">{{reportData.purposeUserQty}}</i><i style="font-weight:600;font-size: 17px;">位</i></p>
                            <p style="line-height: 40px; color: #262626;"><i style="font-weight:600;font-size: 17px;">意向客户</i>的关注，为 <i style="font-size: 26px; color: #FF515F; font-weight: 600;">{{reportData.dealUserQty}}</i><i style="font-weight:600;font-size: 17px;">位</i></p>
                            <p style="line-height: 40px; color: #262626;">已成交的用户提供了优质的产品。</p>
                        </div>
                    </auto-view>
                    <auto-view v-if="reportData.directOrderUserQty > 0" :wh="[324, 80]" psn="absolute" :ps="[166, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 39px; color: #262626;">产品直送用户，2023年您为 <i style="font-size: 26px; color: #FF515F; font-weight: 600;">{{reportData.directOrderUserQty}}</i><i style="font-weight:600;font-size: 17px;">位</i></p>
                            <p style="line-height: 39px; color: #262626;">用户完成 <i style="font-weight:600;font-size: 22px;">{{reportData.directOrderQty}}</i><i style="font-weight:600;font-size: 17px;">次 直送守护</i>，</p>
                        </div>
                    </auto-view>
                    <auto-view v-if="reportData.deliveryOrderUserQty > 0" :wh="[324, 80]" psn="absolute" :ps="reportData.directOrderUserQty > 0 ? [262, 0, 0, 30] : [166, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 39px; color: #262626;">打通送装一体，2023年您为 <i style="font-size: 26px; color: #FF515F; font-weight: 600;">{{reportData.deliveryOrderUserQty}}</i><i style="font-weight:600;font-size: 17px;">位</i></p>
                            <p style="line-height: 39px; color: #262626;">用户提供优质一站式<i style="font-weight:600;font-size: 17px;">送装服务</i>。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[300, 284]" psn="absolute" :ps="[350, 0, 0, 75]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/guke-2.png" alt />
                    </auto-view>
                    <auto-view :wh="[127, 213]" psn="absolute" :ps="[0, 0, 57, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/guke-1.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 活动 -->
                <div class="swiper-slide" :style="{'background': 'url('+require('./images/huodong-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[266, 120]" psn="absolute" :ps="[32, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">这一年，您及团队达成</p>
                            <p style="line-height: 40px; color: #262626;"><i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{Number(reportData.retailSales || 0).toFixed(1)}}</i><i style="font-weight:600;font-size: 17px;">万元</i> 的</p>
                            <p style="line-height: 40px; color: #262626;">海信专卖店<i style="font-weight:600;font-size: 17px;">零售目标</i>，</p>
                        </div>
                    </auto-view>
                    <auto-view v-if="reportData.activityQty > 0" :wh="[324, 80]" psn="absolute" :ps="[170, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 39px; color: #262626;">全年共开展 <i style="font-size: 22px; font-weight: 600;">{{reportData.activityQty}}</i><i style="font-weight:600;font-size: 17px;">场 营销活动</i></p>
                            <p style="line-height: 39px; color: #262626;">实现 <i style="font-weight:600;font-size: 26px; color: #FF515F;">{{Number(reportData.activityRetailSales || 0).toFixed(1)}}</i><i style="font-weight:600;font-size: 17px;">万 零售额</i>。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[46, 72]" psn="absolute" :ps="[113, 26, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/huodong-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[310, 286]" psn="absolute" :ps="[330, 0, 0, 65]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/huodong-3.png" alt />
                    </auto-view>
                    <auto-view :wh="[151, 236]" psn="absolute" :ps="[0, 0, 56, 16]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/huodong-2.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 以旧换新 -->
                <div v-if="reportData.oldNewOrderQty > 0" class="swiper-slide" :style="{'background': 'url('+require('./images/yijiuhuanxin-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[270, 177]" psn="absolute" :ps="[32, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">2023年6月至今，您通过<i style="font-weight:600;font-size: 17px;">以旧换新</i></p>
                            <p style="line-height: 40px; color: #262626;">为 <i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{reportData.oldNewOrderQty}}</i><i style="font-weight:600;font-size: 17px;">位</i> 用户</p>
                            <p style="line-height: 40px; color: #262626;">提供了更便捷的服务，</p>
                            <p style="line-height: 40px; color: #262626;">为<i style="font-weight:600;font-size: 17px;">低碳生活</i>做出了跬步贡献。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[64, 54]" psn="absolute" :ps="[105, 34, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/yijiuhuanxin-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[284, 318]" psn="absolute" :ps="[298, 0, 0, 91]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yijiuhuanxin-4.png" alt />
                    </auto-view>
                    <auto-view :wh="[58, 51]" psn="absolute" :ps="[297, 0, 0, 89]" class="ani ani-delay"
                        swiper-animate-effect="fadeInUp" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yijiuhuanxin-2.png" alt />
                    </auto-view>
                    <auto-view :wh="[159, 217]" psn="absolute" :ps="[0, 0, 77, 24]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yijiuhuanxin-3.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 抖音 -->
                <div v-if="reportData.douyinCouponQty > 0" class="swiper-slide" :style="{'background': 'url('+require('./images/douyinhexiao-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[324, 168]" psn="absolute" :ps="[32, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">2023年6月至今，您在<i style="font-weight:600;font-size: 17px;">抖音平台</i></p>
                            <p style="line-height: 40px; color: #262626;">核销了 <i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{reportData.douyinCouponQty}}</i><i style="font-weight:600;font-size: 17px;">张</i></p>
                            <p style="line-height: 40px; color: #262626;">团购优惠券，成为了</p>
                            <p style="line-height: 40px; color: #262626;"><i style="font-weight:600;font-size: 17px;">零售转型</i>的排头兵！</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[78, 53]" psn="absolute" :ps="[108, 18, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/douyinhexiao-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[324, 308]" psn="absolute" :ps="[319, 0, 0, 51]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/douyinhexiao-3.png" alt />
                    </auto-view>
                    <auto-view :wh="[134, 213]" psn="absolute" :ps="[0, 0, 79, 42]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/douyinhexiao-2.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 小区拓展 -->
                <div v-if="reportData.extendCommunityQty > 0" class="swiper-slide" :style="{'background': 'url('+require('./images/xiaoqutuozhan-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[324, 167]" psn="absolute" :ps="[32, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">2023年8月至今，您<i style="font-weight:600;font-size: 17px;">带领团队</i></p>
                            <p style="line-height: 40px; color: #262626;">拓展了 <i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{reportData.extendCommunityQty}}</i><i style="font-weight:600;font-size: 17px;">个</i> 小区，</p>
                            <p style="line-height: 40px; color: #262626;">那些风吹日晒的时光</p>
                            <p style="line-height: 40px; color: #262626;">是我们<i style="font-weight:600;font-size: 17px;">最珍贵的回忆</i>。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[172, 400]" psn="absolute" :ps="[245, 0, 0, 203]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/xiaoqutuozhan-4.png" alt />
                    </auto-view>
                    <auto-view :wh="[76, 55]" psn="absolute" :ps="[231, 119, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInUp" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/xiaoqutuozhan-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[58, 58]" psn="absolute" :ps="[335, 0, 0, 39]" class="ani ani-delay"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/xiaoqutuozhan-2.png" alt />
                    </auto-view>
                    <auto-view :wh="[147, 256]" psn="absolute" :ps="[0, 0, 71, 49]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/xiaoqutuozhan-3.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 样机管理 -->
                <div v-if="reportData.sampleQty > 0" class="swiper-slide" :style="{'background': 'url('+require('./images/yangjiguanli-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[275, 80]" psn="absolute" :ps="[33, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">2023您共有 <i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{reportData.sampleQty}}</i><i style="font-weight:600;font-size: 17px;">台</i> 机器在</p>
                            <p style="line-height: 40px; color: #262626;"><i style="font-size: 17px; font-weight: 600;">展台展示</i>。</p>
                        </div>
                    </auto-view>
                    <auto-view v-if="reportData.prototypeQty > 0" :wh="[254, 80]" psn="absolute" :ps="[131, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 39px; color: #262626;">通过<i style="font-size: 18px; font-weight: 600;">样机直扣</i>下单 <i style="font-size: 30px; font-weight: 600; color: #FF515F;">{{reportData.prototypeQty}}</i><i style="font-weight:600;font-size: 18px;">台</i>，</p>
                            <p style="line-height: 39px; color: #262626;">节省资金 <i style="font-size: 28px; font-weight: 600; color: #FF515F;">{{Number(reportData.prototypeAmount || 0).toFixed(1)}}</i><i style="font-weight:600;font-size: 18px;">元</i> 。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[70, 70]" psn="absolute" :ps="[122, 21, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/yangjiguanli-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[223, 266]" psn="absolute" :ps="[326, 0, 0, 152]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yangjiguanli-3.png" alt />
                    </auto-view>
                    <auto-view :wh="[178, 289]" psn="absolute" :ps="[0, 0, 53, 15]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/yangjiguanli-2.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 销售结构 -->
                <div v-if="reportData.upscaleRate > 0 || reportData.exclusiveRate > 0" class="swiper-slide" :style="{'background': 'url('+require('./images/xiaoshoujiegou-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="reportData.exclusiveRate > 0 && reportData.upscaleRate > 0 ? [287, 119] : [287, 79]" psn="absolute" :ps="[34, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="line-height: 40px; color: #262626;">2023年您的门店销售结构：</p>
                            <p v-if="reportData.upscaleRate > 0" style="line-height: 40px; color: #262626;">中高端占比 <i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{Number((reportData.upscaleRate || 0) * 100).toFixed(1)}}</i><i style="font-size: 17px; font-weight: 600;">%</i>，</p>
                            <p v-if="reportData.exclusiveRate > 0" style="line-height: 40px; color: #262626;">专供产品占比 <i style="font-size: 26px; font-weight: 600; color: #FF515F;">{{Number((reportData.exclusiveRate || 0) * 100).toFixed(1)}}</i><i style="font-size: 17px; font-weight: 600;">%</i>。</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[297, 80]" psn="absolute" :ps="[175, 0, 0, 26]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="font-size: 17px; line-height: 39px; color: #262626; font-weight: 600;">优化销售结构，提升经营效益。</p>
                            <p style="font-size: 17px; line-height: 39px; color: #262626; font-weight: 600;">2024年，我们的机遇与挑战并存！</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[76, 66]" psn="absolute" :ps="[115, 18, 0, 0]" class="ani ani-delay"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/xiaoshoujiegou-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[244, 262]" psn="absolute" :ps="[310, 0, 0, 131]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/xiaoshoujiegou-3.png" alt />
                    </auto-view>
                    <auto-view :wh="[170, 268]" psn="absolute" :ps="[0, 0, 68, 14]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/xiaoshoujiegou-2.png" alt />
                    </auto-view>
                    <!-- 下一页 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn">
                        <img src="./images/up.png" alt style="width:26px;height:26px" />
                        <p style="font-size: 12px;">向上滑动</p>
                    </auto-view>
                </div>
                <!-- 最终页 -->
                <div class="swiper-slide" :style="{'background': 'url('+require('./images/final-bg.png')+') no-repeat','background-size': '100% 100%'}">
                    <auto-view :wh="[324, 119]" psn="absolute" :ps="[35, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="1s" swiper-animate-delay="0.8s">
                        <div class="text-content">
                            <p style="font-size: 17px; line-height: 40px; color: #262626; font-weight: 600;">万店里程， 与您同行。</p>
                            <p style="line-height: 40px; color: #262626;"><i style="font-size: 17px; font-weight: 600;">2023</i>，我们共同<i style="font-size: 17px; font-weight: 600;">回首过往</i>，</p>
                            <p style="line-height: 40px; color: #262626;"><i style="font-size: 17px; font-weight: 600;">2024</i>，海信期待与您<i style="font-size: 17px; font-weight: 600;">续写辉煌</i>！</p>
                        </div>
                    </auto-view>
                    <auto-view :wh="[274, 24]" psn="absolute" :ps="[181, 0, 0, 30]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/final-4.png" alt />
                    </auto-view>
                    <auto-view :wh="[62, 62]" psn="absolute" :ps="[257, 0, 0, 45]" class="ani ani-delay"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s"
                        swiper-animate-delay="1.2s">
                        <img src="./images/final-1.png" alt />
                    </auto-view>
                    <auto-view :wh="[228, 228]" psn="absolute" :ps="[287, 0, 0, 147]" class="ani"
                        swiper-animate-effect="fadeInRight" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/final-3.png" alt />
                    </auto-view>
                    <auto-view :wh="[184, 184]" psn="absolute" :ps="[421, 0, 0, 0]" class="ani"
                        swiper-animate-effect="fadeInLeft" swiper-animate-duration="2s" swiper-animate-delay="1.2s">
                        <img src="./images/final-2.png" alt />
                    </auto-view>
                    <!-- 重新回忆 -->
                    <auto-view :wh="[60, 48]" psn="absolute" :ps="[0, 0, 42, 158]" class="next animated fadeIn"
                        @click="resetClick">
                        <img src="./images/down.png" alt style="width:26px;height:26px" />
                        <p style="color: #00B7B3; font-size: 12px;">重新回忆</p>
                    </auto-view>
                </div>
            </div>
        </div>
        <auto-view id="music-btn" psn="absolute" :ps="[24, 18, 0, 0]" v-show="this.type == 2"
            :class="this.isPlay ? 'music-icon rotateClass' : 'music-icon'" @click="musicPlayBtnClick">
            <img v-if="isPlay" src="./images/music.png" alt>
            <img v-else src="./images/no-music.png" alt>
        </auto-view>
    </div>
</template>

<script>
import Vue from 'vue';
import * as swiperAni from "./animate.js";
import autoView from "./RespondBox"; //响应式盒子
// 导入api接口
import { getYearReport } from "./api.js";
import "@/common/wx.js";
import moment from "moment";
import { Toast } from 'vant';
Vue.use(Toast);
export default {
    name: "banner",
    components: {
        autoView,
    },
    data() {
        return {
            mySwiper: null,
            startSwiper: null,
            reportData: {},
            musicBtnPlayFlag: 1,
            percentage: 0,
            timer: null,
            type: 1,
            isPlay: true,
        };
    },
    watch: {
        percentage: {
            handler(val) {
                this.$nextTick(() => {
                    this.$el.style.setProperty("--percentage", `${val}%`);
                    this.$el.style.setProperty("--percentagePx", `${val * 1.5}px`)
                })
            },
            immediate: true
        },
        reportData: {
            handler(val) {
                if (val.exclusiveComprehensiveScore && val.exclusiveComprehensiveScore.length == 12) {
                    this.$nextTick(() => this.$el.style.setProperty("--justifyContent", `space-between`))
                } else if (val.exclusiveComprehensiveScore && val.exclusiveComprehensiveScore.length < 12) {
                    this.$nextTick(() => this.$el.style.setProperty("--justifyContent", `space-around`))
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            this.percentage = Math.max(
                this.percentage,
                Math.min(this.percentage + 10, parseInt(100 * Math.random()))
            );
        }, 300);
        $("#nb-box").css(
            "font-size",
            (document.documentElement.clientWidth / 375) * 100 + "px"
        );
        $("#start-box").css(
            "font-size",
            (document.documentElement.clientWidth / 375) * 100 + "px"
        );
        this.getYearReport();
    },
    methods: {
        resetClick() {
            this.mySwiper.slideTo(0, 0, true);
        },
        // 获取活动列表
        getYearReport() {
            let accountId = "";
            if (this.$route.query && this.$route.query.accountId) {
                accountId = this.$route.query.accountId;
            }
            getYearReport({ accountId: accountId })
                .then((res) => {
                    if (res.data && res.data.code == 0) {
                        this.reportData = res.data.data || {};
                        if(this.reportData.putYears){
                            this.reportData.putYears = moment(this.reportData.putYears).format("YYYY年MM月")
                        }
                        if(this.reportData.loginLatestTime){
                            this.reportData.loginLatestTimeStr = moment(this.reportData.loginLatestTime).format("MM月DD日HH:mm")
                        }
                        setTimeout(() => {
                            this.percentage = 100;
                            this.type = 2;
                            clearInterval(this.timer);
                            this.swiperInit();
                        }, 1000)
                    } else {
                        Toast.fail(res.data.msg || "数据获取失败")
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

        },
        musicPlayBtnClick() {
            const music = document.getElementById("musicfx");
            if (music.paused) {
                this.musicBtnPlayFlag = 2;
            } else {
                this.musicBtnPlayFlag = 1;
            }
            this.musicPlayFun();
        },
        //音乐播放
        musicPlayFun() {
            const music = document.getElementById("musicfx");
            if (music.paused) {
                music.play();
                this.isPlay = true;
            } else {
                music.pause();
                this.isPlay = false;
            }
        },
        //跳转下一页
        slideNext() {
            if(this.musicBtnPlayFlag == 1){
                this.musicPlayBtnClick();
            }
            this.mySwiper.slideNext();
        },
        swiperInit() {
            this.$nextTick(() => {
                this.mySwiper = new this.$Swiper(this.$refs.mySwiper, {
                    on: {
                        init: function () {
                            swiperAni.swiperAnimateCache(this); //隐藏动画元素
                            swiperAni.swiperAnimate(this); //初始化完成开始动画
                        },
                        slideChange: function () {
                            swiperAni.swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
                        },
                    },
                    direction: "vertical",
                    parallax: true,
                    autoplay: false,
                    observer: true, //修改swiper自己或子元素时，自动初始化swiper
                    observeParents: true, //修改swiper的父元素时，自动初始化swiper
                });
            });
        },
    },
};
</script>
<style lang="less">
.report-of-annual {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    user-select: none;

    .ani {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .music-icon {
        z-index: 1;
        height: 36px;
        width: 36px;

        img {
            width: 36px;
            height: 36px;
        }
    }

    .swiper-container {
        width: 100vw;
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;

        .ani-delay {
            animation-delay: 1s !important;
            -webkit-animation-delay: 1s !important;
            animation-duration: 2s !important;

            img {
                -webkit-animation-name: hvr-icon-bob-float-2, hvr-icon-bob-2;
                animation-name: hvr-icon-bob-float-2, hvr-icon-bob-2;
                -webkit-animation-duration: 0.3s, 1.5s;
                animation-duration: 0.3s, 1.5s;
                -webkit-animation-delay: 0s, 0.3s;
                animation-delay: 0s, 0.3s;
                -webkit-animation-timing-function: ease-out, ease-in-out;
                animation-timing-function: ease-out, ease-in-out;
                -webkit-animation-iteration-count: 1, infinite;
                animation-iteration-count: 1, infinite;
                -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
                -webkit-animation-direction: normal, alternate;
                animation-direction: normal, alternate;
            }
        }

        .next {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            animation-delay: 1s !important;
            -webkit-animation-delay: 1s !important;
            animation-duration: 2s !important;

            img {
                width: 0.1em;
                height: 0.1em;
                -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
                animation-name: hvr-icon-bob-float, hvr-icon-bob;
                -webkit-animation-duration: 0.3s, 1.5s;
                animation-duration: 0.3s, 1.5s;
                -webkit-animation-delay: 0s, 0.3s;
                animation-delay: 0s, 0.3s;
                -webkit-animation-timing-function: ease-out, ease-in-out;
                animation-timing-function: ease-out, ease-in-out;
                -webkit-animation-iteration-count: 1, infinite;
                animation-iteration-count: 1, infinite;
                -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
                -webkit-animation-direction: normal, alternate;
                animation-direction: normal, alternate;
            }

            p {
                text-align: center;
                font-size: 0.12em;
                color: #7b8397;
            }
        }

        .swiper-slide {
            width: 100%;
            // height: 100% !important;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .progress-report {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title{
            font-size: 14px;
            line-height: 20px;
            color: #262626;
        }

        img {
            width: 148px;
            height: 148px;
            margin-top: 192px;
        }

        .progress-bg {
            width: 150px;
            background: rgba(32, 198, 186, 0.15);
            height: 12px;
            border-radius: 6px;
            margin-top: 4px;
            margin-bottom: 16px;
            position: relative;
            overflow: hidden;

            .progress-color {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #20C6BA;
                height: 12px;
                width: var(--percentage);
                border-radius: 6px;
            }
        }
        .progress-loading{
            width: 200px;
            height: 26px;
            margin-top: 16px;
            padding-left: var(--percentagePx);
            .progress-text {
                font-size: 18px;
                line-height: 20px;
                color: #20C6BA;
                background: url(./images/loading-1.png) no-repeat;
                width: 48px;
                height: 26px;
                background-size: contain;
                padding-top: 1px;

                span {
                    font-size: 12px;
                    line-height: 20px;
                }
            }
        }

    }
    
    .text-content {
        height: 100%;
        display: flex;
        font-size: 15px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around
    }

    .rotateClass {

        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        /* 设置动画，animation:动画名称 动画播放时长单位秒或微秒 动画播放的速度曲线linear为匀速 动画播放次数infinite为循环播放; */
        -webkit-animation: play 3s linear infinite;
        -moz-animation: play 3s linear infinite;
        animation: play 3s linear infinite;
    }

    @-webkit-keyframes play {
        0% {
            -webkit-transform: rotateX(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @-moz-keyframes play {
        0% {
            -moz-transform: rotate(0deg);
        }

        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @keyframes play {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
