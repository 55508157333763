<template>
  <div :style="boxStyle" @click="clickFun">
    <slot></slot>
  </div>
</template>

<script>
/**
  @description 响应式盒子
  @augments wh: 盒子宽高(auto：100%,"height:'width":强制宽高一致)
  @augments mg: 盒子外边距
  @augments pd: 盒子内边距
  @augments psn: 定位
  @augments ps: 定位边距
  @augments zIndex: 层级
  @demo <auto-view :wh=[150,50] :psn="absolute" :ps="[50,0,0,0]"></auto-view>
 */
export default {
  name: "auto-view",
  data() {
    return {};
  },
  created() {},
  props: {
    // 宽高
    wh: {
      type: Array,
      default: () => []
    },
    // 等比例（根据宽计算高）
    ratio: {
      type: Number,
      default: 0
    },
    // 外边距
    mg: {
      type: Array,
      default: () => []
    },
    // 内边距
    pd: {
      type: Array,
      default: () => []
      // 定位
    },
    psn: {
      type: String,
      default: "static"
    },
    // 定位距离
    ps: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 0
    },
  },
  computed: {
    boxStyle() {
      let pageWidth = document.documentElement.clientWidth; // 页面可视窗口宽度
      let pageHeight = document.documentElement.clientHeight;
      // let pageWidth = this.$store.state.user.page_width; // 当前屏幕宽度
      // let pageHeight = this.$store.state.user.page_height; // 当前屏幕高度
      let ratio_w = pageWidth / 375; // 宽度缩放比
      let ratio_h = pageHeight / 724;

      let style = {};
      if(this.zIndex != 0){
        style["z-index"] = this.zIndex;
      }
      // 根据比例设置box宽高
      if (this.wh[0]) {
        if (this.wh[0] == "auto") {
          style.width = "100%";
        } else {
          style.width = ratio_w * this.wh[0] + "px";
        }
      }
      if (this.ratio !== 0) {
        style.height = this.ratio * ratio_w * this.wh[0] + "px";
      } else {
        if (this.wh[1]) {
          if (this.wh[1] == "auto") {
            style.height = "100%";
          } else if (this.wh[1] == "width") {
            style.height = ratio_w * this.wh[0] + "px";
          } else {
            style.height = ratio_h * this.wh[1] + "px";
          }
        }
      }

      // 根据比例设置box外边距
      if (this.mg.length > 0) {
        style["margin-top"] = ratio_h * this.mg[0] + "px";
        style["margin-right"] = ratio_w * this.mg[1] + "px";
        style["margin-bottom"] = ratio_h * this.mg[2] + "px";
        style["margin-left"] = ratio_w * this.mg[3] + "px";
      }

      // 根据比例设置box内边距
      if (this.pd.length > 0) {
        style["padding-top"] = ratio_h * this.pd[0] + "px";
        style["padding-right"] = ratio_w * this.pd[1] + "px";
        style["padding-bottom"] = ratio_h * this.pd[2] + "px";
        style["padding-left"] = ratio_w * this.pd[3] + "px";
      }

      // 根据比例设置box定位
      if (this.psn != "static") {
        style["position"] = this.psn;
        if (this.ps[0] != 0) {
          style["top"] = ratio_h * this.ps[0] + "px";
        }
        if (this.ps[1] != 0) {
          style["right"] = ratio_w * this.ps[1] + "px";
        }
        if (this.ps[2] != 0) {
          style["bottom"] = ratio_h * this.ps[2] + "px";
        }

        if (this.ps[3] != 0) {
          style["left"] = ratio_w * this.ps[3] + "px";
        }
      }

      return style;
    }
  },
  methods: {
    clickFun() {
      this.$emit("click");
    }
  }
};
</script>
